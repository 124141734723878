// Illustrations
import hybrid from '../assets/hybrid.svg'
import culture from '../assets/culture.svg'
import healthcare from '../assets/healthcare.svg'
import ux from '../assets/ux.svg'
import video from '../assets/video.svg'
import business from '../assets/business.svg'
import social from '../assets/social.svg'

// Types
export interface INavItem {
  title: string
  url: string
}

// Content
export const navItems = [
  {
    title: '2021',
    url: 'https://happy2021.jungleminds.com/',
  },
  {
    title: '2020',
    url: 'https://happynewdecade.jungleminds.nl/',
  },
  {
    title: '2019',
    url:
      'https://www.jungleminds.com/blog/7-topics-that-will-shape-the-new-year',
  },
  {
    title: '2018',
    url:
      'https://www.frankwatching.com/archive/2018/01/01/7-trends-bepalen-digitale-wereld-2018/',
  },
  {
    title: '2017',
    url:
      'https://www.frankwatching.com/archive/2017/01/01/deze-6-trends-bepalen-de-digitale-wereld-in-2017/',
  },
  {
    title: '2016',
    url:
      'https://www.frankwatching.com/archive/2016/01/01/deze-7-trends-bepalen-de-digitale-wereld-in-2016/',
  },
  {
    title: '2015',
    url:
      'https://www.frankwatching.com/archive/2015/01/07/wat-zijn-de-belangrijkste-digital-en-webdesigntrends-van-2015/',
  },
] as INavItem[]

export const sections = [
  {
    label: 'Hybrid',
    sectionHeader: {
      title: 'People: Forever ‘Hybrid’',
      illustration: hybrid,
    },
    richText: `
      <p>Like everyone else, you probably tuned into an endless stream of virtual meetings. We all found out that although meeting and collaborating virtually has its challenges, it is more effective than we could have imagined a year ago. Does this mean we are now letting go of physical gatherings - even when COVID-19 has finally left us? Hell no! But we do dare to predict that the line between physical and virtual events has been indefinitely blurred (or even erased altogether).</p>
      <h3>So what will happen?</h3>
      <p>First of all, we expect a massive competition between ‘virtual’ event platforms that will raise the bar in both the quality and experience of remote meetings. Solutions that make your current daily meeting in Teams, Google Meet or Zoom feel like a cruel joke. Or, as the new challenger Welcome puts it, “tools that make it so insanely easy to set up a remote meeting, that feels like an Apple product launch”. New tools will not shy away from levering technologies like AR/VR to really change the feeling of meeting ‘remotely’.</p>
      <p>Despite the rapid advances and quality of digital collaboration, physical spaces will remain essential in how companies work. However, the ‘merging’ of virtual and physical environments will change the spaces we work and collaborate in. Through technology, offices will become hubs for hybrid collaboration in which it will be irrelevant whether you are physically present or working remotely.</p>
    `,
  },
  {
    label: 'Culture',
    sectionHeader: {
      title: 'Future of work: Culture is the holy grail',
      illustration: culture,
    },
    richText: `
      <p>There is no doubt that 2021 will drive an enormous need for digital innovation leading to a big demand for digital talent and the need to onboard new talent. Although the lasting trend of hybrid working (part on-site, part remotely) will offer teams the possibility to tap into talent pools all around the world, the challenge will be to bring this talent together and shape truly collaborative teams.</p>
      <p>So what makes creative, innovating teams effective? Besides having all required skills on board, what really makes the band swing are shared cultural habits: common daily behaviours that allow team members to discover new possibilities and collaborate towards a common goal.</p>
      <p>Yes, culture really does eat strategy for breakfast. Anybody that has ever built a team knows how vital a shared culture is for success. Now that we can expect teams to remain working remotely, the question is what tools and practices team leads can use to define, shape and grow team cultures that allow them to be successful in delivering the needed business innovation.</p>`,
  },
  {
    label: 'Super app',
    sectionHeader: {
      title: 'UX: The Rise of the super app?',
      illustration: ux,
    },
    richText: `
      <p>Probably one of the prevailing ideas in (app) UX is that the best apps are “single purpose”. A messaging app is for messaging. A shopping app is for shopping. Swiss army-knife apps confuse users and therefore simply don't work. At least, that is what we think here in the Western world…</p>
      <p>If we shift the focus to Asia, you immediately find that the so-called “super apps” are insanely popular. Super apps combine several services and basically try to solve any conceivable need a user might have. Think about social networking, messaging, gaming, dating, marketplace, money transferring, currency exchange, insurance, food delivery, taxi, hotel booking and pretty much anything else… All-in-one app. China has WeChat (used by more than a billion people every month), neighbouring countries have others (Grab, from Indonesia, to name one).</p>
      <p>Surprisingly, these super-apps are almost entirely unfamiliar to Western consumers. The question is: will that remain to be the case? Or has 2020 been such a game-changer that even this old dogma is going out the window?</p>
      <p>The latter seems to be true, as Russia’s leading tech company, Yandex, announced the launch of Yandex Go, the first European super app. Meanwhile: ‘Western’ companies like Amazon, Facebook and Google have active super app-like products running in Asia to compete with the local products. These are products that they would gladly roll out in the European market too. So we must ask ourselves: will this be the year when we get introduced to the first European super-app? Or is there too much of a discrepancy between our ‘Western’ cultural habits and other parts of the world? We will leave that in the middle - for now, but the fact is consumer behaviours and adoption of digital technologies has evolved massively in the last year...</p>
    `,
  },
  {
    label: 'healthcare',
    sectionHeader: {
      title: 'Innovation: Digital Healthcare',
      illustration: healthcare,
    },
    richText: `
      <p>To say healthcare was an incredibly important industry last year would be quite the understatement. But, even before 2020, healthcare was seen as a space needing digital-driven innovation. However, due to its size, complexity, and privacy/confidentiality needs, digital innovation in healthcare has proven to be a tough nut to crack. Although the work of the medical sector is intrinsically people-driven, the industry can often feel soulless and far from ‘human-centred’.</p>

      <p>Considering the events of the previous year, this might be the right time for accelerating digital innovation within the healthcare industry. The urgency is most certainly there as was shown by the shortcomings in providing pandemic-related healthcare and regular postponing of ‘routine’ healthcare due to infrastructural needs, an ageing population and increased labour costs due to staff shortages.</p>

      <p>How do we deal with all this? Hopefully, embracing digital technology that enables more efficient AND more human interactions can be a part of the solution. Overall, the trend seems pretty clear: more and more of our healthcare interaction will take place through screens. This makes the need to design human-centric systems only bigger. If done right, digital solutions with a human-centric design can truly help empower people to take control of their own health. However, if done poorly, digitisation can backfire and amplify the sterile interactions for which the healthcare industry is all too well known for.</p>

      <p>It would be truly great if the pandemic turns out to be the start of the long-anticipated wave of innovation that the healthcare industry needs. As long as human-centricity drives the change, we truly feel it can be a change for the better. Let's hope 2021 will prove to be a leap into the right direction!</p>
    `,
  },
  {
    label: 'Video first',
    sectionHeader: {
      title: 'E-commerce: Video First',
      illustration: video,
    },
    richText: `
      <p>Video is huge - and about to get even bigger. The current role video platforms like YouTube and TikTok play in e-commerce still feels a little ‘old school’. However, if we take Asian e-commerce trends as an indication of our future direction, one can see that’s all about to change. Big time. Short video clips are the future of e-commerce and very soon well going to be shopping on video apps.</p>
      <p>In the West, the most common business model for video-platforms is still ad-based. Currently, e-commerce platforms consider video platforms as traffic generators. Yet, in Asia, the short video format (think TikTok/Instagram and, recently, YouTube) has led to a new era of native e-commerce. Retailers have successfully started to use video apps as a platform not only for entertainment - but also for shopping.</p>
      <p>For example: On Taobao, China’s largest e-commerce platform, 42% of product pages already include short videos and live streaming is growing quickly. The share of revenue that the company is generating in sales through live streams is growing exponentially. It is no surprise that short videos through apps like TikTok and Instagram have already proven to be the next frontier for e-commerce in the US and the EU. These are all fuelled by the rise of native shop-options and smart integrations with popular third-party platforms.</p>
    `,
  },
  {
    label: 'D2C',
    sectionHeader: {
      title: 'Business: the second wave of D2',
      illustration: business,
    },
    richText: `
      <p>One of the biggest shifts the internet has brought to business in the last decade is that almost every company has become a <strong>D2C (direct-to-consumer) company</strong>. By vertically integrating their value chain, they gain more control (and if they are doing it right: better margins). Or in normal people language: they cut out the middle man.</p>
      <p>Going ‘direct’ is about much more than just 'selling online'. Winning in a direct strategy demands winning your audience by setting new industry standards for customer experience. And that strategy can only work if you are truly willing to redefine your internal capabilities, your company set up, and your tech stack. For example, what Spotify and Netflix did for entertainment. What Nespresso did for coffee. What WarbyParker (or its European counterpart Ace&Tate) did for eyewear. And what Peleton is doing for fitness. They combine their product with clever additional digital services, creating a winning combination, that keep users begging for more.</p>
      <p>We have all seen this shift play out, mostly in consumer products. Initially it started out with new ‘digital native’ companies that used digital channels to disrupt their verticals and accesing their end-users directly. Yet in the last few years, we have seen several at first ‘non-digital’, companies and industries move towards this model.</p>
      <ul>
        <li><strong>Nike</strong> is moving away from wholesale and marketplaces to double down on DTC.</li>
        <li>In recent releases, <strong>Disney</strong> has skipped cinemas and premiered directly on their self-owned channel, Disney Plus.</li>
        <li>At <strong>Tesla</strong>, there no network of cheesy car dealers involved. You order online, visit their offline showroom and pick your car up from the Tesla parking lot.</li>
      </ul>
      <p>Our prediction: 2021 will be the perfect time to break open industries that haven't gone ‘direct’ yet. Because every time consumers embrace new ways of interacting with companies, new possibilities for ‘direct’ companies open up.</p>
    `,
  },
  {
    label: 'Social+',
    sectionHeader: {
      title: 'Social+ (Social media strikes back)',
      illustration: social,
    },
    richText: `
      <p>In just a matter of years, social platforms have switched from a happy, magical place for human connection - to being framed as the digital equivalent of the devils. In the light of this, we shouldn’t expect anything positive from ‘social’ in 2021 - or should we?</p>
      <p>Wrong! If you look beyond the headlines, you can see pretty exciting ‘social’ innovation on it’s way. Investors have named this trend ‘Social+’, referring to a new generation of social networks that are built around one theme or category. Tiktok is an example you probably know - others are Fortnite, Minecraft and Pinduoduo. Social+ companies take a one category (e.g. gaming, music - or even shopping) and build an integrated social experience around it.</p>
      <p>What makes these Social+ communities so interesting is that they work fundamentally differently. Social platforms like Facebook, Instagram, Snapchat, and Twitter are built on a traditional ad-based business model. A model that only works by selling an enormous scale of user data to keen advertisers (and the same model that has become increasingly criticized the past few years).</p>
      <p>These new <a href="https://a16z.com/2020/12/07/social-strikes-back-fastest-growing-apps/" target="_blank">emerging entrants</a> do not even compare to the incumbent social giants in scale, user penetration or hyper-personalized targeting capabilities - and they don’t intend to.They are taking notes from new players in Asia, from the gaming industry, and from early ad-adverse pioneers like Tinder and Spotify (who never primarily built their business on an ad-based model).</p>
    `,
  },
]

export const inPageNavItems = sections
  .map(section => ({
    label: section.label,
  }))
  .unshift({ label: 'Intro' })
