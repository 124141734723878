import React from 'react'
import styled, { css } from 'styled-components'

// Styling
import { duration, ease } from '../../styles/animations'

interface IProps {
  isActive: boolean
  width?: number
  height?: number
  color?: string
  className?: string
}

const CrossSVG = ({ className, width, height, color, isActive }: IProps) => (
  <Wrapper
    className={className}
    style={{
      width: `${String(width)}px`,
      height: `${String(height)}px`,
    }}
    viewBox="0 0 24 24"
    fill={color}
    isActive={isActive}
  >
    <g fillRule="evenodd">
      <SquareTop d="M16 0v8H8V0z" isActive={isActive} />
      <Line d="M24 8v8H0V8z" />
      <SquareBot d="M16 16v8H8v-8z" isActive={isActive} />
    </g>
  </Wrapper>
)

const sharedDefaultSquareStyles = css`
  opacity: 0;
  transform: scaleY(0.1);
  transition: opacity ${duration.fast}ms ${ease.cubic},
    transform ${duration.fast}ms ${ease.cubic};
`

const sharedActiveSquareStyles = css`
  opacity: 1;
  transform: scaleY(1);
`

const Line = styled.path``
const SquareTop = styled.path`
  ${sharedDefaultSquareStyles};
  opacity: 0;
  transform-origin: center top;

  ${(props: { isActive: boolean }) =>
    props.isActive && sharedActiveSquareStyles}
`
const SquareBot = styled.path`
  ${sharedDefaultSquareStyles};
  transform-origin: center bottom;

  ${(props: { isActive: boolean }) =>
    props.isActive && sharedActiveSquareStyles}
`

const Wrapper = styled.svg`
  transform-origin: center center;
  transform: ${(props: { isActive: boolean }) =>
    props.isActive ? 'rotate(45deg)' : 'rotate(-45deg)'};
  transition: transform ${duration.medium}ms ${ease.cubic} 100ms;

  &:hover {
    ${SquareTop},
    ${SquareBot} {
      ${(props: { isActive: boolean }) =>
        props.isActive ? 'transform: scaleY(0.9);' : 'opacity: 1;'}
    }
  }
`

export default CrossSVG
