import React from 'react'
import styled, { css } from 'styled-components'

// Styling
import { mq } from '../styles/utils/mq'
import colors from '../styles/colors'
import textStyles from '../styles/textStyles'
import { duration, ease } from '../styles/animations'

// Content
import { navItems } from '../content'

// Types
import { INavItem } from '../content'

// Setup
const DELAY_MULTIPLIER = 50
const LINK_BASE_DELAY = 100

const SiteNav = ({ isSiteHeaderExpanded, collapseSiteHeader }: any) => {
  return (
    <Nav isExpanded={isSiteHeaderExpanded}>
      <NavList>
        {navItems.map((item: INavItem, index) => (
          <NavItem key={index}>
            <LinkWrapper
              isExpanded={isSiteHeaderExpanded}
              index={index - 1}
              maxIndex={navItems.length - 1}
            >
              <Link
                href={item.url}
                onClick={() => collapseSiteHeader()}
                target={index === 0 ? '_self' : '_blank'}
              >
                {item.title}
              </Link>
            </LinkWrapper>
          </NavItem>
        ))}
      </NavList>
    </Nav>
  )
}

// Shared styles
const sharedNavListStyles = css`
  margin: 0;
  list-style: none;
`

const sharedLinkStyles = css`
  display: inline-block;
  padding-top: 0;
  color: ${colors.black};
  text-decoration: none;

  &:hover {
    color: ${colors.white};
  }
`

// Main styles
const Nav = styled.nav`
  visibility: ${(props: { isExpanded: boolean }) =>
    !props.isExpanded && 'hidden'};
  margin-bottom: calc(var(--base-column-size) / 2);
  transition: visibility ${duration.slow}ms ${ease.default};
`

const NavList = styled.ul`
  ${sharedNavListStyles};
`

const NavItem = styled.li`
  position: relative;
  overflow: hidden;
  margin-bottom: 0;
  padding-bottom: 24px;

  ${mq.from.M`
    padding-bottom: 30px;
  `}

  ${mq.from.L`
    padding-bottom: calc(var(--base-column-size) / 4);
  `}
`

const LinkWrapper = styled.div<{
  isExpanded: boolean
  index: number
  maxIndex: number
}>`
  /* -110% is to hide descenders (tails) of letters like 'y' or 'g' */
  transform: ${({ isExpanded }) =>
    isExpanded ? 'translateY(0%)' : 'translateY(-110%)'};
  transition: transform ${duration.medium}ms ${ease.cubic};
  transition-delay: ${({ isExpanded, index, maxIndex }) =>
    isExpanded
      ? `
        ${index * DELAY_MULTIPLIER + LINK_BASE_DELAY}ms
      `
      : `
        ${(maxIndex - index) * DELAY_MULTIPLIER}ms
    `};
`

const Link = styled.a`
  ${textStyles.headingM}
  ${sharedLinkStyles}
  text-decoration: none;

  ${mq.from.M`
    ${textStyles.headingL};
  `}

  ${mq.from.L`
    ${textStyles.headingSBold};
  `}
`

export default SiteNav
