import { css } from 'styled-components'

const fonts = {
  primary: "'neue-haas-grotesk-display', sans-serif",
}

const fontFaces = css`
  /* The import below includes the following fonts:
  - Neue Haas Grotesk Display Pro 55 Roman
  - Neue Haas Grotesk Display Pro 56 Italic
  - Neue Haas Grotesk Display Pro 65 Medium
  - Neue Haas Grotesk Display Pro 75 Bold */
  @import url('https://use.typekit.net/xvg1mnp.css');
`

const fontWeights = {
  medium: 500,
  semiBold: 600,
  bold: 700,
}

export { fonts, fontFaces, fontWeights }
