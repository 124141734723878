/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react'
import styled from 'styled-components'

// Styling
import GlobalStyle from '../styles/index'
import { fontFaces } from '../styles/fonts'

// Components
import Menu from '../components/Menu'
import Footer from '../components/Footer'

const Layout: React.FC = ({ children }) => {
  return (
    <Wrapper>
      <GlobalStyle />

      <Menu />

      <Container>
        <main>{children}</main>
      </Container>

      <Footer />
    </Wrapper>
  )
}

// Main styles
const Wrapper = styled.div`
  ${fontFaces};
`

const Container = styled.div`
  margin: 0 auto;
`

export default Layout
