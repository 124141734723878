import { css } from 'styled-components'

// Styling
import { fonts, fontWeights } from './fonts'
import { mq } from '../styles/utils/mq'
import colors from '../styles/colors'

// Shared styles
const sharedHeadingStyles = css`
  font-family: ${fonts.primary};
  font-weight: ${fontWeights.bold};
  text-rendering: optimizeLegibility;
`
// Main styles
export default {
  headerTitle: css`
    ${sharedHeadingStyles};
    font-size: 48px;
    line-height: 1;
    letter-spacing: -1px;

    ${mq.from.M`
      font-size: 70px;
      line-height: 1.29;
    `}

    ${mq.from.L`
      font-size: 90px;
      line-height: 1.22;
    `}

    ${mq.from.XL`
      font-size: 110px;
      line-height: 1;
    `}

    ${mq.from.XXL`
      font-size: 120px;
    `}
  `,
  headerHeading: css`
    ${sharedHeadingStyles};
    font-size: 40px;
    line-height: 1;
    letter-spacing: -1px;

    ${mq.from.M`
      font-size: 64px;
    `}

    ${mq.from.L`
      font-size: 80px;
    `}

    ${mq.from.XL`
      line-height: 1.12;
      letter-spacing: -0.75px;
    `}
  `,
  bodyLabel: css`
    font-family: ${fonts.primary};
    font-size: 16px;
    line-height: 1.19;

    ${mq.from.M`
      line-height: 1.5;
    `}

    ${mq.from.XL`
      font-size: 18px;
    `}

    ${mq.from.XXL`
      font-size: 30px;
      line-height: 1.4;
    `}
  `,
  introHeading: css`
    font-family: ${fonts.primary};
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: -0.67px;

    ${mq.from.M`
      font-size: 20px;
      line-height: 1.33;
    `}

    ${mq.from.L`
      font-size: 24px;
    `}

    ${mq.from.XL`
      font-size: 40px;
      line-height: 1.32;
    `}

    ${mq.from.XXL`
      font-size: 60px;
      line-height: 1.13;
    `}
  `,
  headingSBold: css`
    ${sharedHeadingStyles}
    font-size: 18px;
    line-height: 1.11;
    letter-spacing: -0.67px;

    ${mq.from.M`
      font-size: 24px;
      line-height: 1.12;
    `}

    ${mq.from.XL`
      font-size: 40px;
    `}

    ${mq.from.XXL`
      font-size: 60px;
      line-height: 1.13;
    `}
  `,
  headingSRegular: css`
    font-family: ${fonts.primary};
    font-size: 24px;
    font-weight: 500;
    line-height: 1.12;
    letter-spacing: -0.75px;

    ${mq.from.XL`
      font-size: 40px;
      letter-spacing: -0.5px;
    `}

    ${mq.from.XXL`
      font-size: 60px;
      line-height: 1.13;
      letter-spacing: -0.67px;
    `}
  `,
  rank: css`
    ${sharedHeadingStyles}
    font-size: 48px;
    line-height: 1;
    letter-spacing: -1.5px;

    ${mq.from.M`
      font-size: 64px;
    `}

    ${mq.from.XL`
      font-size: 101px;
    `}

    ${mq.from.XXL`
      font-size: 160px;
    `}
  `,
  headingL: css`
    ${sharedHeadingStyles};
    font-size: 38px;
    line-height: 1;
    letter-spacing: -0.666667px;

    ${mq.from.M`
      font-size: 48px;
    `}

    ${mq.from.XL`
      font-size: 80px;
    `}

    ${mq.from.XXL`
      font-size: 120px;
      letter-spacing: -1px;
    `}
  `,
  headingM: css`
    ${sharedHeadingStyles};
    font-size: 24px;
    line-height: 1.12;
    letter-spacing: -0.75px;

    ${mq.from.M`
      font-size: 32px;
    `}

    ${mq.from.XL`
      font-size: 52px;
      line-height: 1.13;
    `}

    ${mq.from.XXL`
      font-size: 80px;
      line-height: 1.12;
    `}
  `,
  body: css`
    font-family: ${fonts.primary};
    font-size: 16px;
    line-height: 1.5;

    ${mq.from.M`
      font-size: 20px;
    `}

    ${mq.from.XL`
      font-size: 24px;
    `}

    ${mq.from.XXL`
      font-size: 30px;
      line-height: 1.4;
    `}
  `,
  hyperlink: css`
    color: ${colors.black};
    text-decoration: none;

    span {
      position: relative;
    }

    :hover {
      text-decoration: underline;
    }
  `,
  downloadButton: css`
    font-family: ${fonts.primary};
    font-size: 14px;
    line-height: 1.29;
    font-weight: 700;

    ${mq.from.XL`
      font-size: 16px;
      line-height: 1.25;
    `}

    /* 16px + */
    ${mq.from.XXL`
      font-size: 16px;
      font-size: calc(var(--base-fluid-font-size) * 1.1);
    `}
  `,
  contact: css`
    font-family: ${fonts.primary};
    font-size: 18px;
    line-height: 1.33;
    letter-spacing: -0.67px;

    ${mq.from.M`
      font-size: 24px;
      line-height: 1.12;
    `}

    ${mq.from.XL`
      font-size: 40px;
    `}

    ${mq.from.XXL`
      font-size: 60px;
      line-height: 1.13;
    `}
  `,
  menuItems: css`
    font-family: ${fonts.primary};
    font-weight: ${fontWeights.bold};
    font-size: 16px;
    line-height: 1.5;

    ${mq.from.L`
      font-size: 18px;
    `}

    ${mq.from.XXL`
      font-size: 30px;
      line-height: 1.4;
    `}
  `,
  inPageNav: css`
    font-family: ${fonts.primary};
    font-size: 12px;
    line-height: 1;

    ${mq.from.XL`
      font-size: 16px;
    `}

    ${mq.from.XXL`
      font-size: 20px;
      line-height: 1.6;
    `}
  `,
}
