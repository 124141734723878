import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

// Styling
import { mq } from '../styles/utils/mq'
import zIndices from '../styles/zIndices'
import colors from '../styles/colors'
import { duration, ease } from '../styles/animations'

// Components
import NavBar from './NavBar'
import Nav from './Nav'

const Menu = () => {
  const [isSiteHeaderExpanded, setIsSiteHeaderExpanded] = useState<boolean>(
    false
  )

  const handleResize = () => {
    if (window !== undefined && document !== undefined) {
      if (document.documentElement.clientWidth >= 768) {
        setIsSiteHeaderExpanded(false)
      }
    }
  }

  useEffect(() => {
    handleResize()

    if (window !== undefined) {
      window.addEventListener('resize', handleResize)
    }

    return () => {
      if (window !== undefined) {
        window.removeEventListener('resize', handleResize)
      }
    }
  }, [])

  const toggleSiteHeader = () => {
    setIsSiteHeaderExpanded(prevState => !prevState)
  }

  return (
    <>
      <NavBar
        isSiteHeaderExpanded={isSiteHeaderExpanded}
        toggleSiteHeader={toggleSiteHeader}
      />
      <Header isExpanded={isSiteHeaderExpanded}>
        <Content>
          <Nav
            isSiteHeaderExpanded={isSiteHeaderExpanded}
            collapseSiteHeader={setIsSiteHeaderExpanded}
          />
        </Content>
      </Header>
      <Overlay isExpanded={isSiteHeaderExpanded} />
    </>
  )
}

// Main styles
const Overlay = styled.div`
  position: fixed;
  z-index: ${zIndices.siteHeaderOverlay};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${colors.accent1};
  transform-origin: top center;
  transform: ${(props: { isExpanded: boolean }) =>
    props.isExpanded ? 'scale3d(1, 1, 1)' : 'scale3d(1, 0, 1)'};
  transition: transform ${duration.medium}ms ${ease.cubic};

  /* Ideally, this should be done using react-spring to keep the animations in sync */
  transition-delay: ${(props: { isExpanded: boolean }) =>
    props.isExpanded ? '0' : '250ms'};
`

const Header = styled.header<{ isExpanded: boolean }>`
  position: fixed;
  z-index: ${zIndices.siteHeader};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding-top: 18.75vw;

  /* hide scrollbar in IE and Edge */
  -ms-overflow-style: none;

  ${mq.from.M`
    padding-top: 9.375vw;
  `}

  /* Override IE styles */
  padding-top: calc(var(--base-column-size) * 1.5);

  ${({ isExpanded }) =>
    isExpanded
      ? `
      overflow-x: hidden;
      overflow-y: auto;
    `
      : 'visibility: hidden;'}

  /* Custom text highlighting */
  *::-moz-selection {
    background-color: rgba(0, 0, 0, 0.99);
    color: ${colors.accent1};
  }

  *::selection {
    background-color: rgba(0, 0, 0, 0.99);
    color: ${colors.accent1};
  }

  /* Add a cover for the content to scroll under */
  &::before {
    content: '';
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    left: 0;
    height: 100%;
    ${(props: { isExpanded: boolean }) =>
      props.isExpanded && 'max-height: 18.75vw;'}
    background-color: ${colors.accent1};
    transition: max-height ${duration.medium}ms ${ease.cubic};

    ${mq.from.M`
      ${(props: { isExpanded: boolean }) =>
        props.isExpanded && 'max-height: 9.375vw;'}
    `}

    /* Override IE styles */
    ${({ isExpanded }) =>
      isExpanded
        ? `
        max-height: calc(var(--base-column-size) * 1.5);
      `
        : 'max-height: 0'};
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  /* Add some "safe" space for phones that display their navbar at the bottom */
  padding: 12px 12px 80px;

  ${mq.from.L`
    padding-bottom: 30px;
  `}
`

export default Menu
