import React from 'react'
import styled, { css } from 'styled-components'

// Components
import ContainerComponent from './layout/Container'
import Logo from './Logo'
import LogoCompact from './LogoCompact'
import CrossSVGComponent from './icons/Cross'

// Styling
import { mq } from '../styles/utils/mq'
import colors from '../styles/colors'
import textStyles from '../styles/textStyles'

// Content
import { navItems } from '../content'

// Types
import { INavItem } from '../content'

const NavBar = ({ toggleSiteHeader, isSiteHeaderExpanded }: any) => {
  return (
    <Wrapper isSiteHeaderExpanded={isSiteHeaderExpanded}>
      <Container>
        <LogoLink
          href="https://jungleminds.com"
          aria-label="Jungle Minds Homepage"
          tabIndex={0}
        >
          <MobileLogo theme={isSiteHeaderExpanded ? 'dark' : 'light'} />
          <DesktopLogo theme="light" />
        </LogoLink>
        {navItems.map((item: INavItem, i) => (
          <PreviousArticle
            key={`previous-article-${i}`}
            href={item.url}
            target={i === 0 ? '_self' : '_blank'}
            tabIndex={0}
          >
            {item.title}
          </PreviousArticle>
        ))}
        <ToggleBtn
          aria-label="Main navigation"
          tabIndex={0}
          onClick={() => toggleSiteHeader()}
        >
          <CrossSVG
            isActive={isSiteHeaderExpanded}
            color={isSiteHeaderExpanded ? colors.black : colors.white}
          />
        </ToggleBtn>
      </Container>
    </Wrapper>
  )
}

// Shared styles
const sharedSVGStyles = css`
  /* IE Fallback styles */
  height: 26px;

  ${mq.from.M`
    height: 43px;
  `}

  ${mq.from.L`
    height: 48px;
  `}

  ${mq.from.XL`
    height: 57px;
  `}

  ${mq.from.XXL`
    max-width: 25vw;
    height: 3.75vw;
  `}
`

// Main styles
const Wrapper = styled.div<{ isSiteHeaderExpanded: boolean }>`
  position: ${({ isSiteHeaderExpanded }) =>
    isSiteHeaderExpanded ? 'fixed' : 'absolute'};
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
`

const Container = styled(ContainerComponent)`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 14px;

  ${mq.from.M`
    padding: 48px;
  `}

  ${mq.from.L`
    padding: 40px;
  `}

  ${mq.from.XL`
    padding-top: 40px;
    padding-bottom: 40px;
  `}
`

const LogoLink = styled.a`
  line-height: 0;
  text-decoration: none;
  margin-right: auto;

  ${mq.from.XXL`
    height: 100%;
  `}
`

const MobileLogo = styled(Logo)`
  ${sharedSVGStyles}

  ${mq.from.M`
    display: none;
  `}
`

const DesktopLogo = styled(LogoCompact)`
  display: none;

  ${mq.from.M`
    ${sharedSVGStyles}
    display: block;
  `}
`

const PreviousArticle = styled.a`
  display: none;

  ${textStyles.hyperlink}
  ${textStyles.menuItems}
  color: ${colors.white};

  /* IE Fallback styles */
  margin-right: 24px;

  ${mq.from.M`
    display: block;
    margin-right: 32px;
  `}

  ${mq.from.XL`
    margin-right: 50px;
  `}

  ${mq.from.XXL`
    max-width: 25vw;
    margin-right: 60px;
  `}

  /* Override IE styles */
  margin-right: calc(var(--base-column-size) / 2);

  &:last-of-type {
    margin-right: 0;
  }
`

const ToggleBtn = styled.button`
  height: 100%;
  padding: 0;
  background: none;
  border: none;
  line-height: 0;
  cursor: pointer;

  ${mq.from.M`
    display: none;
  `}
`

const CrossSVG = styled(CrossSVGComponent)`
  ${sharedSVGStyles};
`

export default NavBar
