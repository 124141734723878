/**
 * Adding z-indices here gives a good overview of the stacking indexes' order.
 */

export default {
  siteNavBar: 110,
  siteHeader: 100,
  siteHeaderOverlay: 90,
  pageTransitionsCanvas: -10,
  pageTransitionsBackground: -20,
}
