import React from 'react'
import styled from 'styled-components'

// Styling
import { mq } from '../styles/utils/mq'

// Components
import { Col } from './layout/Grid'
import Logo from './Logo'
import AnimatedScrollWrapper from './animation/AnimatedScrollWrapper'

const Footer = () => {
  return (
    <StyledFooter>
      <Col xxs={4} xxsOffset={2} m={4} mOffset={6}>
        <AnimatedScrollWrapper>
          <a href="https://jungleminds.com">
            <Logo theme="light" wordmark />
          </a>
        </AnimatedScrollWrapper>
      </Col>
    </StyledFooter>
  )
}

const StyledFooter = styled.footer`
  display: flex;
  padding-top: 24px;
  padding-bottom: 288px;

  ${mq.from.M`
    padding-top: 48px;
    padding-bottom: 48px;
  `}

  ${mq.from.XXL`
    padding-top: 64px;
    padding-bottom: 64px;
  `}
`

export default Footer
