import styled from 'styled-components'

// Styling
import { mq } from '../../styles/utils/mq'

const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  margin: 0 auto;

  ${mq.from.XXL`
    padding-right: 30px;
    padding-left: 30px;
  `}
`

export default Container
