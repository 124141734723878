import { css } from 'styled-components'

// Utils
import {
  NO_SCROLL_CLASSNAME,
  NO_OVERFLOW_CLASSNAME,
} from '../utils/constants/classNames'

// Styling
import { mq } from '../styles/utils/mq'
import colors from './colors'
import textStyles from './textStyles'
import { duration, ease } from './animations'

export const base = css`
  /* NOTE: With this setup, use the --base-fluid-font-size as your root size, not rems or ems. */
  :root {
    /* px fallback in case vw is not supported */
    --base-fluid-font-size: 16px;
    --base-fluid-font-factor: 20;
    /**
     * Calculate fluid base font size with a starting equivalent of 16px for a 320px viewport.
     *
     * The calculation will reset it to 16px up to the largest breakpoint,
     * after which it will scale to infinity.
     */
    --base-fluid-font-size: calc(100vw / var(--base-fluid-font-factor));

    /**
     * @NOTE: IE 11 doesn't support vars, so just use the equivalent vw values
     * 1 col in an 8 col grid = 12.5vw
     * 1 col in a 16 col grid = 6.25vw
     */
    /* Set the nr. of columns */
    --base-columns: 8;
    /* Calculate the base width of a column to help with fluid styling*/
    --base-column-size: calc(100vw / var(--base-columns));

    ${mq.from.M`
      --base-fluid-font-factor: 48;
      --base-columns: 16;
    `}

    ${mq.from.XL`
      --base-fluid-font-factor: 80;
    `}

    ${mq.from.XXL`
      --base-fluid-font-factor: 120;
    `}
  }

  /* Custom text highlighting */
  ::-moz-selection {
    background-color: rgba(250, 70, 22, 0.99);
    color: ${colors.black};
  }

  ::selection {
    background-color: rgba(250, 70, 22, 0.99);
    color: ${colors.black};
  }

  html,
  body {
    width: 100%;
    height: 100%;
    ${textStyles.body};
    margin: 0;
    color: ${colors.white};
    background-color: ${colors.black};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    -webkit-tap-highlight-color: transparent;
  }

  #root {
    width: 100%;
    height: 100%;
  }

  p {
    ${textStyles.body};
    margin-bottom: 24px;
  }

  picture {
    line-height: 0;
  }

  img {
    margin-bottom: 0;
  }

  /* Scroll lock */
  .${NO_SCROLL_CLASSNAME} {
    overflow-y: hidden;
  }

  /* No overflow on full width & height pages */
  .${NO_OVERFLOW_CLASSNAME} {
    /* Only target non-touch devices */
    @media not all and (hover: none) {
      overflow: hidden;
    }
  }

  /**
   * Disable native browser outlines when clicking
   *
   * For more information on this plugin, take a look at:
   * https://github.com/WICG/focus-visible
   *
   * :focus-visible should be implemented in its place
   * once it's properly supported by more vendors.
   */
  .js-focus-visible *:focus:not([data-focus-visible-added]) {
    outline: none;
  }

  /* Fade styles for react-transition-group */
  .page-anim {
    &--appear,
    &--enter {
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      left: 0;
      width: 100%;
      opacity: 0;
    }

    &--appear-active,
    &--enter.page-anim--enter-active {
      opacity: 1;
      transition: opacity ${duration.pageContentTransition}ms ${ease.cubic}
        ${duration.pagePatternTransition}ms;
    }

    &--exit {
      opacity: 1;
    }

    &--exit.page-anim--exit-active {
      opacity: 0;
      transition: opacity ${duration.pageContentTransition}ms ${ease.cubic};
    }
  }
`

export default base
