import { keyframes } from 'styled-components'

export const ease = {
  default: 'ease-in-out',
  cubic: 'cubic-bezier(0.4, 0, 0.2, 1)',
  animationWrapperCubic: 'cubic-bezier(0, 1, 0.5, 1)',
}

export const duration = {
  fast: 150,
  medium: 250,
  slow: 500,
  pageContentTransition: 500,
  pagePatternTransition: 1250,
}

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export const enterOnScroll = keyframes`
  to {
    transform: translateY(0);
  }
`
